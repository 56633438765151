import React from "react"
import LayoutRoot from "../components/LayoutRoot"
import Background from "../components/reusable/Background"
import { FlexContainer } from "../styles/layout.styled"
import { P } from "../styles/shared.styled"
import Navigate from "../components/OurWork/Work/Navigate"
import { graphql } from "gatsby"
import OneWork from "../components/OurWork/Work/OneWork"
import VideoPlayer from "../components/reusable/VideoPlayer"
import styled from "styled-components"

const headerBg = "rgba(18, 36, 54, 0.6)"

const StyledWatchVideo = styled.p`
  margin: 0;
  font-size: 24px;
  font-weight: 500;
  color: ${({theme}) => theme.white};
  line-height: 28px;

  @media only screen and (max-width: ${({ theme }) => theme.mobile}) {
    font-size: 20px;
  }
`

const StyledVideoContainer = styled.div`
  max-width: 1046px;
  margin: 0 auto;
  padding-inline: 14px;
  width: 100%;
`

const Work = props => {
  const { data, pageContext } = props
  const { edges } = data?.works || []
  const { next, previous, node } = edges?.find(({ node }) => node.id === pageContext.id)

  return (<>
    <LayoutRoot background={headerBg} withFooter>
      <Background fluid={node?.workCover?.fluid} height="350px">
        <FlexContainer flexDirection="column" padding="150px 0 0">
          <P
            margin="0 0 10px"
            color="white"
            fontSize="24px"
            fontWeight="bold"
          >
            {node?.workTitle?.toUpperCase()?.replace("-", " ")}
          </P>
          <P color="white" fontSize="18px">
            {node?.subCategory?.toUpperCase()?.replace("-", " ")}
          </P>
        </FlexContainer>
      </Background>
      <FlexContainer gap="32px" flexDirection="column">
        <OneWork node={node} />
        <StyledWatchVideo>
          Watch video
        </StyledWatchVideo>
        <StyledVideoContainer>
          <VideoPlayer url={node?.videoUrl} height="unset" aspectRatio={1.4} />
        </StyledVideoContainer>
        <Navigate pageContext={{ next, previous }} />
      </FlexContainer>
    </LayoutRoot>
  </>)
}

export default Work

export const query = graphql`
  query ($category: String!) {
    works: allContentfulOurWork(filter: { workCategory: {eq: $category} }) 
    {
       edges {
          next {
            id
            workCategory
            subCategory
          }
          previous {
            id
            workCategory
            subCategory
          }
          node {
            id
            workCategory
            subCategory
            workTitle
            aboutUs
            description
            videoUrl
            workCover {
               fluid(quality: 100) {
                 src
                 base64
                 aspectRatio
                 srcSet
                 sizes
               }
            } 
            projectPhotos {
               fluid(quality: 100) {
                 src
                 base64
                 aspectRatio
                 srcSet
                 sizes
               }
            }
          }
      }    
    }
  }
`
