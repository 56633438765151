import React from "react"
import styled from "styled-components"
import Image from "gatsby-image"
import { FlexContainer } from "../../../styles/layout.styled"

const OneWorkContainer = styled(FlexContainer)`
  padding: 42px 14px 0;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`

const Description = styled.p`
  color: ${({ theme: { white } }) => `${white}CC`};
  margin: 24px 0 ;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  max-width: 750px;
  padding: 0 20px;
  @media only screen and (max-width: ${({ theme }) => theme.mobile}) {
    font-size: 14px;
  }
`

const MainImageContainer = styled(FlexContainer)`
  flex-wrap: wrap;
  gap: 24px;
  justify-content: space-between;
  align-items: center;
`

const SquareImageContainer = styled(FlexContainer)`
  width: 48%;

  @media only screen and (max-width: ${({ theme }) => theme.tablet}) {
    width: 47%;
    margin: ${({ mobileMargin }) => mobileMargin};
  }

  @media only screen and (max-width: ${({ theme }) => theme.mobile}) {
    width: 100%;
    margin: ${({ mobileMargin }) => mobileMargin};
  }
`

const StyledSquaredImage = styled(Image)`
  width: 100%;
  height: 100%;
  aspect-ratio: 1.5;
`

const StyledHorizontalImageContainer = styled(FlexContainer)`
  width: 100%;
  height: 100%;
`

const StyledHorizontalImage = styled(Image)`
  width: 100%;
  height: 100%;
  aspect-ratio: 2.6;
`



const OneWork = ({ node }) => {
  const {
    description,
    aboutUs,
    projectPhotos
  } = node || {}
  const squaredImages = projectPhotos?.slice(0, 4)
  const horizontalImage = projectPhotos?.[4]
  return (
    <OneWorkContainer>
      <Description>
        {description}
      </Description>
      <MainImageContainer>
        {squaredImages?.map(({ fluid }, index) => (
          <SquareImageContainer key={index}>
            <StyledSquaredImage
              fluid={fluid}
              loading="auto"
              fadeIn
            />
          </SquareImageContainer>
        ))}

      </MainImageContainer>
      <Description>
        {aboutUs}
      </Description>
      <StyledHorizontalImageContainer>
        <StyledHorizontalImage
          fluid={horizontalImage?.fluid}
          loading="auto"
          fadeIn
        />
      </StyledHorizontalImageContainer>
    </OneWorkContainer>
  )
}

export default OneWork
