import React from "react"
import { FlexContainer } from "../../../styles/layout.styled"
import { navigate } from "gatsby"
import styled from "styled-components"
import Arrow from "../../../assets/svg/arrow.svg"

const ButtonsContainer = styled(FlexContainer)`
  max-width: 1294px;
  margin: 0 auto;
  justify-content: space-between;
  padding-inline: 14px;
`

const NavigateButton = styled.button`
  cursor: pointer;
  background: transparent;
  color: ${({ theme: { white } }) => white};
  font-weight: bold;
  font-size: 12px;
  border: none;
  display: flex;
  align-items: center;
  padding: 10px 0;
  &:disabled {
    color: ${({ theme: { white } }) => white};
    opacity: 0.5;
    cursor: not-allowed;
  }

  .right-arrow {
    margin-left: 20px;
  }

  .left-arrow {
    margin-right: 20px;
    transform: rotate(-180deg);
  }
`

const Navigate = ({ pageContext }) => {
  const { next, previous } = pageContext
  return (
    <ButtonsContainer>
      <NavigateButton
        disabled={!previous?.id}
        onClick={() =>
          previous?.id
            ? navigate(`/our-work/${previous?.workCategory}/${previous?.subCategory}/${previous?.id}`)
            : null
        }
      >
        <Arrow className="left-arrow" /> previews Project
      </NavigateButton>
      <NavigateButton
        disabled={!next?.id}
        onClick={() =>
          next?.id
            ? navigate(`/our-work/${next?.workCategory}/${next?.subCategory}/${next?.id}`)
            : null
        }
      >
        Next Project <Arrow className="right-arrow" />
      </NavigateButton>
    </ButtonsContainer>
  )
}

export default Navigate
