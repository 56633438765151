import React from "react"
import styled from "styled-components"
import { COLUMNS_LOCATION } from "../../constants"
import Image from "gatsby-image"

const BackgroundContainer = styled.div`
  background-image: ${({ bgUrl }) => `url(${bgUrl})`};
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  width: 100%;
  height: ${({ height }) => height || "auto"};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const StyledImageContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
  aspect-ratio: 4.1;

  :after {
    content: '';
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background: linear-gradient(0deg, rgba(18,36,54, 0.6) 30%, rgba(255,255,255,0) 100%);
  }
`
const StyledImage = styled(Image)`
  width: 100%;
  height: inherit;
`

const Columns = styled.div`
  width: 2px;
  background: rgba(50, 61, 72, 0.4);
  position: absolute;
  top: 0;
  bottom: 0;
  left: ${({ left }) => left};
`

const Background = ({
                      children,
                      bgUrl,
                      height,
                      withColumns = false,
                      fluid
                    }) => {
  return (
    <>
      <BackgroundContainer height={height} bgUrl={bgUrl}>
        {fluid && (
          <StyledImageContainer>
          <StyledImage
            fluid={fluid}
            loading="auto"
            fadeIn
          />
          </StyledImageContainer>
        )}
        {withColumns &&
          COLUMNS_LOCATION.map(column => (
            <Columns key={column} left={`${column}%`} />
          ))}
        {children}
      </BackgroundContainer>
    </>
  )
}

export default Background
